import { useState, useEffect, useMemo } from 'react';
import { any, array, bool, func, object, string } from 'prop-types';
import {
  BlockMargin,
  Flex,
  FlexWrapperStartMiddle,
  StyledSelect,
  TextLight
} from 'base/styled';
import styled from 'styled-components';
import FormTextField from 'base/components/FormTextField';
import { useTranslation } from 'react-i18next';
import FormTextFieldTime from 'base/components/FormTextFieldTime';
import Location from '../Filters/Location';
import { makeAsOptions } from 'base/utils';

const Wrapper = styled(FlexWrapperStartMiddle)`
  position: relative;
  min-height: 65px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
    margin: 10px 0;
    .bsfINW {
      width: 100%;
      min-width: auto;
    }
  }
`;

const BlockMarginRelative = styled(BlockMargin)`
  position: relative;
  input[type='number'] {
    margin-right: 10px;
  }
`;

const TextLightAbsolute = styled(TextLight)`
  position: absolute;
  top: 47px;
  font-weight: 400!important;
`;

const Block = styled(BlockMargin)`
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const TimeSpec = styled.div`
  position: relative;
  top: 58px;
  input {
    margin-bottom: 10px;
  }
`;

const TimeSelect = styled.div`
  position: relative;
  top: 48px;
  .bottom {
    margin-bottom: 10px;
  }
`;

function CascadQueue({
  handlerType,
  handlerEqual,
  handlerEqualNewVisitor,
  handlerValue,
  edit,
  styles,
  item,
  users,
  setIsSelectValue,
  gic,
  naics,
  setSelectUser,
  setNewVisitor,
  PathHandler,
  TimeHandler,
  CountHandler,
  pathOperator,
  timeOperator,
  countOperator,
  ValueToHandler,
  ValueFromHandler,
  notAssigned,
  setNotAssigned,
  setError,
  queue
}) {
  const { t: homeT } = useTranslation('home');
  const [selected, setSelected] = useState(edit ? item.key : item.key || '' );
  const [input, setInput] = useState(true);
  const [time, setTime] = useState(false);
  const [tree, setTree] = useState(false);
  const [location, setLocation] = useState(false);
  const [timeSpec, setTimeSpec] = useState(false);
  const [between, setBetween] = useState(false);
  const [operator, setOperator] = useState(edit ? item.operator : '');
  function changeSelectOptionHandler(event, item) {
    setSelected(event.value);
    handlerType(event, item);
  }

  const filterTypesMap = useMemo(() => ({
    employees: {value: 'employees', label: 'Approximate Employees'},
    assigned: {value: 'assigned', label: 'Assigned'},
    company_name: {value: 'company_name', label: 'Company Name'},
    full_contact_city: {value: 'full_contact_city', label: 'Headquarters City'},
    full_contact_region: {value: 'full_contact_region', label: 'Headquarters Region'},
    full_contact_country: {value: 'full_contact_country', label: 'Headquarters Country'},
    industry: {value: 'industry', label: 'NAICS Industry'},
    gic_industry: {value: 'gic_industry', label: 'GIC Industry'},
    keyword: {value: 'keyword', label: 'Keyword'},
    visits: {value: 'visits', label: 'Number of Page Views'},
    page_path: {value: 'page_path', label: 'Page Path'},
    query: {value: 'query', label: 'Query String'},
    source: {value: 'source', label: 'Source'},
    time_on_page: {value: 'time_on_page', label: 'Time on Website'},
    city: {value: 'city', label: 'Visitor Location City'},
    country: {value: 'country', label: 'Visitor Location Country'},
    region: {value: 'region', label: 'Visitor Location State/Province'},
    tag: {value: 'tag', label: 'Tag'},
    domain: {value: 'domain', label: 'Domain'},
    time_on_specific_page: { value: 'time_on_specific_page', label: 'Time on Specific Page' },
    views_count_of_specific_page: { value: 'views_count_of_specific_page', label: 'View of Specific Page' },
    new_visitor: { value: 'new_visitor', label: 'New Visitor' },
  }), []);

  const filterTypesOptions = useMemo(() => Object.values(filterTypesMap), [filterTypesMap]);

  useEffect(() => {
    setSelected(item.key);
  }, [item]);

  const equalsOptions = [
    { value: '=' || 'eq', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: 'contains', label: 'contains' },
    { value: 'does not contain', label: 'does not contain' },
  ];

  const equalsOptionsGr = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<', label: 'less than' },
    { value: '<=', label: 'less than or equal to' },
  ];

  const equalsOptionsBetween = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
    { value: '>', label: 'greater than' },
    { value: '>=', label: 'greater than or equal to' },
    { value: '<', label: 'less than' },
    { value: '<=', label: 'less than or equal to' },
    { value: 'between', label: 'between'}
  ];

  const equal = [
    { value: '=', label: 'equals' },
    { value: '!=', label: 'not equal to' },
  ];

  const boolOptions = [
    { value: 'yes', label: 'yes' },
    { value: 'no', label: 'no' },
  ];

  const [type, setType] = useState(equalsOptions);
  const [value, setValue] = useState(null);
  const anyUser = {label: 'Any user', id: 'any', value: 'any'};
  useEffect(() => {
    if (
      selected === 'company_name' ||
      selected === 'keyword' ||
      selected === 'page_path' ||
      selected === 'source' ||
      selected === 'tag' ||
      selected === 'domain'
    ) {
      setInput(true);
      setTime(false);
      setType(equalsOptions);
      setIsSelectValue(false);
      setTimeSpec(false);
    } else if(selected === 'visits'){
      setInput(true);
      setTime(false);
      setType(equalsOptionsGr);
      setIsSelectValue(false);
      setTimeSpec(false);
    } else if (
      selected == 'city' ||
      selected == 'country' ||
      selected == 'region' ||
      selected == 'full_contact_city' ||
      selected == 'full_contact_country' ||
      selected == 'full_contact_region' 
    ) {
      setInput(false);
      setTime(false);
      setType(equalsOptions);
      //setIsSelectValue(false);
      setLocation(true);
      setTree(true);
      setIsSelectValue(true);
      setTimeSpec(false);
      //setValue(naics);
    } else if (selected === 'assigned') {
      setInput(false);
      setValue([...usersOptions, anyUser]);
      setSelectUser(true);
      setType(boolOptions);
      setIsSelectValue(true);
      setTimeSpec(false);
    } else if (selected === 'time_on_page') {
      setTime(true);
      setInput(true);
      setIsSelectValue(false);
      setType(equalsOptionsGr);
      setTimeSpec(false);
    } else if (selected === 'time_on_specific_page' ||
      selected === 'views_count_of_specific_page' ) {
      setTime(false);
      setTimeSpec(true);
      setInput(true);
      setIsSelectValue(false);
    } else if (selected === 'employees' || selected === 'visits') {
      setType(equalsOptionsBetween);
      setTime(false);
      setInput(true);
      setIsSelectValue(false);
      setTimeSpec(false);
    } else if (selected === 'gic_industry') {
      setTime(false);
      setInput(false);
      setTree(false);
      setIsSelectValue(true);
      setType(equal);
      setTimeSpec(false);
      setValue(gic);
      setLocation(false);
    } else if (selected === 'industry') {
      setTime(false);
      setInput(false);
      setTree(true);
      setIsSelectValue(true);
      setType(equal);
      setValue(naics);
      setTimeSpec(false);
      setLocation(false);
    } else if (selected === 'new_visitor') {
      setInput(false);
      setType(boolOptions);
      setNewVisitor(true);
      setTimeSpec(false);
    } else {
      setSelected(item.key);
      setInput(false);
      setTree(false);
      setIsSelectValue(false);
      setTimeSpec(false);
    }
    setError();
    //console.log(multi);
  /* eslint-disable */
  }, [item, selected]);
  /* eslint-enable */
  
  useEffect(() => {
    if (operator == 'between') {
      setBetween(true);
    }
  }, [operator]);
  
  const handleOperator = (e) => {

    if(selected == 'new_visitor' || (selected=='assigned' && e.value == 'no')) {
      handlerEqualNewVisitor(e, item);
    } else {
      handlerEqual(e, item);
    }
    setOperator(e.value);
    if(e.value == 'between') {
      setBetween(true);
    } else {
      setBetween(false);
    }
  };

  useEffect(() => {
    if(selected == 'assigned' && item.operator == 'no' ) {
      setNotAssigned(true);
    } else {
      setNotAssigned(false);
    }
    /* eslint-disable */
  }, [selected, operator, item]);
   /* eslint-enable */
  const usersOptions = queue ? users : makeAsOptions(users, 'id', 'full_name');
  const setUsers = item.value == 'any' ? anyUser : queue ? users.filter(u => 
    item.value.split(',').includes(u.value?.toString()) ||
    item.value==u.value).map(obj => {
    return { ...obj, label: obj.label };
  }) :
    users.filter(u => 
      item.value.split(',').includes(u.id?.toString()) ||
      item.value==u.id).map(obj => {
      return { ...obj, label: obj.full_name};
    });

  const [gicFiltered, setGicFiltered] = useState([]);
  useEffect(() => {
    if(gic) {
      setGicFiltered(gic.filter(({ value }) => item.value.split(',').includes(value)));
    }
  }, [gic, item]);

  const defaultValues = useMemo(() => {
    if (!edit || !item.value) return '';
    const selectedValues = item.value.split(',');
    return []
      .concat(...naics.map(({ options }) => options || []))
      .filter(({ value }) => selectedValues.includes(value));
  }, [edit, item.value, naics]);

  return (
    <Wrapper>
      <Block>
        <StyledSelect
          placeholder="Filter Type"
          options={Object.values(filterTypesMap)}
          onChange={(e) => changeSelectOptionHandler(e, item)}
          styles={styles}
          value={filterTypesMap[selected]}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
        />
      </Block>
      <Block className='form-field'>
        {timeSpec ? (
          <TimeSelect>
            <StyledSelect
              options={equalsOptions}
              onChange={(e) => pathOperator(e, item)}
              styles={styles}
              className="bottom"
              defaultValue={type.find((el) => el.value === item.multiple_filter?.path_operator)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
            />
            {selected == 'views_count_of_specific_page' ? (
              <StyledSelect
                options={equalsOptionsGr}
                onChange={(e) => countOperator(e, item)}
                styles={styles}
                defaultValue={equalsOptionsGr.find((el) => el.value === item.multiple_filter?.count_operator)}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />) : (
              <StyledSelect
                options={equalsOptionsGr}
                onChange={(e) => timeOperator(e, item)}
                styles={styles}
                defaultValue={equalsOptionsGr.find((el) => el.value === item.multiple_filter?.time_operator)}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            )}
            
          </TimeSelect>
        ) : (
          <StyledSelect
            options={type}
            onChange={handleOperator}
            styles={styles}
            value={(selected == 'new_visitor' || selected == 'assigned') ? 
              boolOptions.find((el) => el.value === item.operator) : 
              type.find((el) => el.value === item.operator)
              // {label: "yes", value: 'yes'}
            }
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null
            }}
          />
        )}
      </Block>
      {selected !== 'new_visitor' && !notAssigned && (
        <BlockMarginRelative className='form-field'>
          {input ? (
            <>
              {time ? (
                <>
                  <FormTextFieldTime
                    onChange={(e) => handlerValue(e, item)}
                    value={item.value}
                    label={homeT('sec')}
                  />
                  
                </>
              ) : timeSpec ? (
                <TimeSpec>
                  <div>
                    <FormTextField
                      onChange={(e) => PathHandler(e, item)}
                      value={item.multiple_filter?.path_value}
                      label={homeT('pathOfSpec')}
                    />
                  </div>
                  <div>
                    {selected == 'views_count_of_specific_page' ? (
                      <FormTextFieldTime
                        onChange={(e) => CountHandler(e, item)}
                        value={item.multiple_filter?.count_value}
                        defaultValue={item.key}
                      />
                    ) : (
                      <FormTextFieldTime
                        onChange={(e) => TimeHandler(e, item)}
                        value={item.multiple_filter?.time_value}
                        label={homeT('sec')}
                        defaultValue={item.key}
                      />
                    )}
                  </div>
                </TimeSpec>
              ) : between ? (
                <Flex>
                  <FormTextField
                    type="number"
                    onChange={(e) => ValueFromHandler(e, item)}
                    value={item.multiple_filter?.value_from}
                    placeholder={homeT('from')}
                  
                    // defaultValue={item.value}
                  />
                  <FormTextField
                    type="number"
                    onChange={(e) => ValueToHandler(e, item)}
                    value={item.multiple_filter?.value_to}
                    placeholder={homeT('to')}
                    // defaultValue={item.value}
                  />
                </Flex>
              ) : (
                <FormTextField
                  type="text"
                  onChange={(e) => handlerValue(e, item)}
                  value={item.value}
                  label={homeT('value')}
                  defaultValue={item.value}
                />
              )}
              {!time && !timeSpec && !between && (
                <TextLightAbsolute>{homeT('useCommas')}</TextLightAbsolute>
              )}
            </>
          ) : (tree && !location) ? (            
            <StyledSelect
              isClearable
              isSearchable
              options={value}
              isMulti
              styles={styles}
              onChange={(e) => handlerValue(e, item)}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              defaultValue={defaultValues}
            />
          ) : tree && location ? (
            <>
              <Location
                data={filterTypesOptions}
                styles={styles}
                selected={selected}
                item={item}
                handlerValue={handlerValue}
                edit={edit}
                defaultValue={edit ? item.value : ''}
              />
            </>
          ) : (
            <>
              <StyledSelect
                options={value}
                isMulti
                styles={styles}
                onChange={(e) => handlerValue(e, item)}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                defaultValue={
                  edit ? 
                    selected == 'assigned' ? setUsers
                      : gicFiltered
                    : ''
                }
              />
            </>
          )}
        </BlockMarginRelative>
      )}
    </Wrapper>
  );
}
  
export default CascadQueue;

CascadQueue.propTypes = {
  handlerType: func, 
  handlerEqual: func, 
  handlerEqualNewVisitor: func,
  styles: object,
  handlerValue: func,
  edit: bool,
  item: any,
  users: array,
  setIsSelectValue: func,
  gic: array,
  naics: array,
  setNewVisitor: func,
  setSelectUser: func,
  newc: bool,
  PathHandler: func,
  path: string,
  TimeHandler: func,
  timeValue: string,
  CountHandler: func,
  count: string,
  pathOperator: func,
  timeOperator: func,
  countOperator: func,
  ValueToHandler: func,
  ValueFromHandler: func,
  notAssigned: any,
  setNotAssigned: func,
  setError: func,
  queue: bool
};